<template>
    <base-content>
        <template #button>
            <a-button type="primary" @click="showEdit(0)">新增</a-button>
        </template>
        <a-breadcrumb style="margin-bottom:10px">
            <a-breadcrumb-item></a-breadcrumb-item>
            <a-breadcrumb-item v-for="item in breadcrumb" :key="item.id" @click="parent_id = item.id">
                <a>{{ item.title }}</a>
            </a-breadcrumb-item>
            <a-breadcrumb-item></a-breadcrumb-item>
        </a-breadcrumb>
        <a-table :dataSource="table_data" :columns="columns" :pagination="false" bordered rowKey="id">
            <template #operation="scoped">
                <a-button-group>
                    <a-button type="link" size="small" @click="parent_id = scoped.record.id">进入</a-button>
                    <a-button type="link" size="small" @click="showEdit(scoped.record.id)">编辑</a-button>
                    <a-button type="link" size="small" @click="$refs.DataLogModal.show(scoped.record.id)">管理</a-button>
                    <a-button type="link" size="small">删除</a-button>
                </a-button-group>
            </template>
        </a-table>
        <DataLogModal ref="DataLogModal" data_table="category" />
        <a-modal v-model:visible="edit_modal_visible" :title="edit_modal_title" @ok="saveEdit">
            <a-form ref="edit_form" :model="edit_form" :label-col="{ span: 4, offset: 1 }" :rules="edit_rule" :wrapper-col="{ span: 15 }">
                <a-form-item name="name" label="分类名称">
                    <a-input v-model:value="edit_form.name"></a-input>
                </a-form-item>
                <a-form-item name="description" label="描述">
                    <a-input v-model:value="edit_form.description"></a-input>
                </a-form-item>
                <a-form-item name="parent_id" label="上级分类">
                    <a-tree-select v-model:value="edit_form.parent_id" :treeData="category_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }" treeDefaultExpandAll />
                </a-form-item>
                <a-form-item name="prefix" label="分类前缀">
                    <a-select v-model:value="edit_form.prefix" showSearch :options="total_prefix_options" :disabled="edit_form.unuse_prefix"></a-select>
                    <a-checkbox v-model:checked="edit_form.unuse_prefix">该分类不使用前缀</a-checkbox>
                </a-form-item>
            </a-form>
        </a-modal>
    </base-content>
</template>

<script>
import DataLogModal from '../components/modal/DataLog.vue';
export default {
    components: {
        DataLogModal,
    },
    data() {
        let map = '0123456789';
        let total_prefix_list = [];
        let total_prefix_options = [];
        for (let i1 in map) {
            for (let i2 in map) {
                let t = map[i1] + map[i2];
                total_prefix_options.push({ value: t, label: t, key: t });
                total_prefix_list.push(t);
            }
        }
        map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
        for (let i1 in map) {
            for (let i2 in map) {
                let t = map[i1] + map[i2];
                if (total_prefix_list.indexOf(t) == -1) {
                    total_prefix_options.push({ value: t, label: t, key: t });
                    total_prefix_list.push(t);
                }
            }
        }
        return {
            breadcrumb: [],
            parent_id: 0,
            table_data: [],
            columns: [
                {
                    title: '名称',
                    dataIndex: 'name',
                    align: 'center',
                },
                {
                    title: '描述',
                    dataIndex: 'description',
                },
                {
                    title: '编码前缀',
                    dataIndex: 'prefix',
                    align: 'center',
                    width: 90,
                },
                {
                    title: '操作',
                    slots: { customRender: 'operation' },
                    width: 180,
                    align: 'center',
                },
            ],
            edit_modal_visible: false,
            edit_modal_title: '新增物料分类',
            edit_category_id: 0,
            edit_form: {},
            edit_rule: {
                name: [{ required: true, message: '请输入储存区名称', trigger: 'blur' }],
                description: [{ message: '请输入储存区描述', trigger: 'blur' }],
                prefix: [{ required: false, min: 2, max: 2, message: '请输入正确的前缀' }],
            },
            category_tree: [],
            total_prefix_list,
            total_prefix_options,
        };
    },
    watch: {
        parent_id(value) {
            let insert_flag = true;
            let new_breadcrumb = [];
            for (let i = 0; i < this.breadcrumb.length; i++) {
                if (this.breadcrumb[i].id == value) {
                    insert_flag = false;
                    new_breadcrumb.push(this.breadcrumb[i]);
                } else if (insert_flag) {
                    new_breadcrumb.push(this.breadcrumb[i]);
                }
            }
            if (insert_flag) {
                for (let i = 0; i < this.table_data.length; i++) {
                    if (this.table_data[i].id == value) {
                        new_breadcrumb.push({ id: value, title: this.table_data[i].name });
                    }
                }
            }
            this.breadcrumb = new_breadcrumb;
            this.getData();
        },
    },
    mounted() {
        this.getData();
        this.$api('getCategoryList', { parent_id: -1 }).then((res) => {
            res.category_list.forEach((item) => {
                if (item.id == 0) {
                    this.breadcrumb = [{ id: 0, title: item.name }];
                }
            });
        });
    },
    methods: {
        getData(ban_cache = false) {
            this.$api('getCategoryList', { parent_id: this.parent_id }, ban_cache).then((res) => {
                this.table_data = res.category_list;
            });
        },
        showEdit(category_id) {
            this.edit_modal_visible = true;
            this.edit_category_id = category_id;
            this.$api('getCategoryTree', {}, true).then((res) => {
                this.category_tree = res.category_tree;
            });
            this.$api('getUsedCategoryPrefixList', {}, true).then((res) => {
                this.total_prefix_options.forEach((item) => {
                    item.disabled = res.used_category_prefix_list.indexOf(item.value) != -1;
                });
            });
            if (category_id == 0) {
                this.edit_modal_title = '新增物料分类';
                this.edit_form = { name: '', description: '', parent_id: this.parent_id, prefix: '', unuse_prefix: false };
            } else {
                for (let i = 0; i < this.table_data.length; i++) {
                    if (this.table_data[i].id == category_id) {
                        this.edit_modal_title = `编辑分类 【${this.table_data[i].name}】`;
                        let unuse_prefix = this.total_prefix_list.indexOf(this.table_data[i].prefix) == -1;
                        let prefix = unuse_prefix ? '' : this.table_data[i].prefix;
                        this.edit_form = { name: this.table_data[i].name, description: this.table_data[i].description, parent_id: this.table_data[i].parent_id, prefix, unuse_prefix };
                    }
                }
            }
        },
        saveEdit() {
            this.$refs.edit_form.validate().then((res) => {
                let data = {
                    name: this.edit_form.name,
                    description: this.edit_form.description,
                    parent_id: this.edit_form.parent_id,
                    prefix: this.total_prefix_list.indexOf(this.edit_form.prefix) == -1 || this.edit_form.unuse_prefix ? '' : this.edit_form.prefix,
                };
                if (this.edit_category_id == 0) {
                    this.$api('addCategory', data).then((res) => {
                        if (res.result == true) {
                            this.$message.success('新增成功');
                            this.$api('getCategoryList', { parent_id: data.parent_id }, true).then((res) => {
                                this.getData(true);
                            });
                        } else {
                            this.$message.error('新增失败');
                        }
                        this.edit_modal_visible = false;
                    });
                } else {
                    data.category_id = this.edit_category_id;
                    this.$api('editCategory', data).then((res) => {
                        if (res.result == true) {
                            this.$message.success('修改成功');
                            this.$api('getCategoryList', { parent_id: data.parent_id }, true).then((res) => {
                                this.getData(true);
                            });
                        } else {
                            this.$message.error('修改失败');
                        }
                        this.edit_modal_visible = false;
                    });
                }
            });
        },
    },
};
</script>

<style></style>
